import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { logout } from "../../actions/authenticationAction";
import { connect } from "react-redux";
import {
  getAllAlerts,
  handleError,
  handleInfo,
  handleClickError,
  handleModal,
  getCollectorProcess,
} from "../../actions/collectorAction";
import SimpleReactValidator from "simple-react-validator";
import "./AltHeader.scss";
import axios from "axios";
import { getMissedAlerts } from "../../actions/collectorAction";
import AlertTable from "./../Notifications/Table";
import Axios from "axios";
import {
  API_URL,
  JAVA_API_URL,
  collectorConstants,
} from "../../constants/ActionTypes";
import apiAxios from "../../api";
import { alertActions } from "../../actions";
import CachedIcon from "@mui/icons-material/Cached";
import CommonApi from "./Network/agentMonitorApi";
import { MdExpandMore } from "react-icons/md";

const { REACT_APP_JAVA_API_URL } = process.env;
const collektoUser = JSON.parse(localStorage.getItem("collektoUser"));

const statusOptions = [
  // { value: "none", label: "Break" },
  { value: "lunch", label: "Lunch Break" },
  { value: "tea", label: "Tea Break" },
  { value: "bio", label: "Bio" },
  { value: "teammeeting", label: "Team Meeting" },
  { value: "assistances", label: "Supervisor Support" },
  { value: "QAF", label: "Quality Audit Feedback" },
  { value: "training", label: "Training " },
];

const { username, role, location } = collektoUser || {};

class AltHeader extends Component {
  isModalVisible = false;
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const { collektoUser } = this.props.authentication;
    const { manualProcess, assignedPredictiveProcess } =
      this.props.collectors.collectorProcess || {};
    this.manualProcess = manualProcess;
    this.assignedPredictiveProcess = assignedPredictiveProcess;
    // const agentActive = localStorage.getItem("agentActive");
    const agentReady = localStorage.getItem("agentReady");
    console.log(agentReady, typeof agentReady, "status agent");
    //  console.log(`header this.props.authentication`, this.props.authentication)
    document.body.classList.add("mini-sidebar");
    if (this.alertsInterval) {
      clearInterval(this.alertsInterval);
    }
    this.alertsInterval =
      collektoUser?.role === "COLLECTOR"
        ? setInterval(() => this.props.getAllAlerts(collektoUser?._id), 120000)
        : null;
    if (collektoUser && collektoUser?.role === "CUSTOMER") {
      // document.body.classList.add('single-column');
      // document.body.classList.remove('mini-sidebar');
    }

    if (collektoUser?.role == "COLLECTOR") {
      this.props.getMissedAlerts(collektoUser._id);
    }

    this.state = {
      agentStatus: {},
      isModalVisible: false, // Add isModalVisible to component state
      showMiniSideBar: true,
      selectedLabel: null,
      isOpen: false,
      // agentActive: !agentActive
      //   ? "true"
      //   : agentActive == "true"
      //   ? "true"
      //   : "false",
      agentReady: !agentReady
        ? "true"
        : agentReady === "true"
        ? "true"
        : "false", // if agent ready is not present in local storage then make it by default true , if it is present give it the value that is in local
    };
    this.selectRef = React.createRef();
  }
  static propTypes = {
    authentication: PropTypes.object.isRequired,
  };

  componentDidUpdate() {
    const { manualProcess, assignedPredictiveProcess } =
      this.props.collectors.collectorProcess || {};
    this.manualProcess = manualProcess;
    this.assignedPredictiveProcess = assignedPredictiveProcess;
  }

  componentDidMount() {
    if (role === "COLLECTOR" && location == "D") {
      this.props.getCollectorProcess(username);
    }

    // change this to only location D similarly showing part
    if (role === "COLLECTOR" && location == "D") {
      // this.props.getCollectorProcess(username);
      this.agentMonitorStatus();
      this.interval = setInterval(this.agentMonitorStatus, 30000);
    }
    // document.body.classList.add('mini-sidebar');
    // this.setState({showMiniSideBar: true})
    window.addEventListener("click", this.handleWindowClick);
  }

  componentWillUnmount() {
    clearInterval(this.alertsInterval);
    if (role === "COLLECTOR" && location == "D") {
      clearInterval(this.interval);
    }
    window.removeEventListener("click", this.handleWindowClick);
  }

  handleWindowClick = (event) => {
    const { isOpen } = this.state;
    if (isOpen && !event.target.closest(".btn-dropdown")) {
      this.setState({ isOpen: false });
    }
  };

  handleButtonClick = () => {
    const { isOpen } = this.state;
    // if (isOpen) {
    //   this.selectRef.current.blur(); // Close the dropdown
    // } else {
    //   this.selectRef.current.focus(); // Open the dropdown
    // }
    this.setState({ isOpen: !isOpen });
  };

  toggleAgentActive = () => {
    console.log(this.props, "proops");

    this.setState((prevState) => ({
      agentReady: prevState.agentReady === "true" ? "false" : "true",
    }));
    if (localStorage.getItem("agentReady") === "true") {
      localStorage.setItem("agentReady", "false");
    } else {
      localStorage.setItem("agentReady", "true");
    }
  };

  handleSelectChange = async (selectedOption) => {
    console.log(selectedOption, "selopt");

    const predictive = localStorage.getItem("isPredictive");
    let process;
    if (predictive && predictive !== "false") {
      process = this.assignedPredictiveProcess;
    } else {
      process = this.manualProcess;
    }

    const payload = {
      username,
      breakName: selectedOption.value,
      processName: process,
    };
    await axios
      .post(`${JAVA_API_URL}/api/v2/dialer/break`, payload)
      .then((res) => {
        res = res.data;
        if (res.success) {
          alertActions.info("Agent is in break mode");
          // this.setState({ agentActive: "false" });
          // localStorage.setItem("agentActive", "false");
          this.toggleAgentActive();
          this.setState({ isOpen: !this.state.isOpen });
        }
      })
      .catch((e) => {
        alertActions.error(e);
      });
  };

  handleReadyChange = async () => {
    await axios
      .post(`${JAVA_API_URL}/api/v2/dialer/readyCall`, { username })
      .then((res) => {
        res = res.data;
        if (res.success) {
          alertActions.info("Agent is in idle mode");
          this.toggleAgentActive();
          setTimeout(() => {
            this.agentMonitorStatus();
          }, 1000);
        }
      })
      .catch((e) => {
        alertActions.error(e);
      });
  };

  handleMenuClick = () => {
    console.log(`this click`);
    // if (document.body.classList.contains('mini-sidebar')) {
    if (this.state.showMiniSideBar) {
      document.body.classList.remove("mini-sidebar");
      this.setState({ showMiniSideBar: false });
    } else {
      document.body.classList.add("mini-sidebar");
      this.setState({ showMiniSideBar: true });
    }
  };

  logout = () => {
    const { collektoUser } = this.props.authentication;
    console.log(`header collekto user `, collektoUser.username);
    apiAxios.post(`${REACT_APP_JAVA_API_URL}/api/v2/dialer/logout`, {
      username: collektoUser.username,
    });
    this.props.logout();
  };
  handleAlertCountClick = () => {
    console.log("coming handleAlertCountClick");
    this.props.dispatch({
      type: collectorConstants.SHOWALERTSMODAL,
      payload: true,
    });
  };

  handleCloseModal = (value) => {
    this.props.handleCloseModal(value);
  };

  changeAgentStatus = async () => {
    const predictive = localStorage.getItem("isPredictive");
    const agentStatus = localStorage.getItem("agentActive");
    let process;
    if (predictive && predictive !== "false") {
      process = this.assignedPredictiveProcess;
    } else {
      process = this.manualProcess;
    }
    const payload = {
      username,
      breakName: "lunch",
      processName: process,
    };
    if (agentStatus === "true" || agentStatus == null) {
      await axios
        .post(`${JAVA_API_URL}/api/v2/dialer/break`, payload)
        .then((res) => {
          res = res.data;
          if (res.success) {
            alertActions.info("Agent is in break mode");
            this.setState({ agentActive: "false" });
            localStorage.setItem("agentActive", "false");
          }
        })
        .catch((e) => {
          alertActions.error(e);
        });
    } else {
      await axios
        .post(`${JAVA_API_URL}/api/v2/dialer/readyCall`, { username })
        .then((res) => {
          res = res.data;
          if (res.success) {
            alertActions.info("Agent is in idle mode");
            this.setState({ agentActive: "true" });
            localStorage.setItem("agentActive", "true");
          }
        })
        .catch((e) => {
          alertActions.error(e);
        });
    }
  };

  // fetch user's status
  agentMonitorStatus = async () => {
    try {
      const data = await CommonApi.getAgentMonitorStatus(collektoUser.username);
      console.log(data, "status agent");
      const status = data.data.data;

      if (status?.status === "MISSED")
        this.setState({ agentStatus: data.data.data, agentReady: false });
      else this.setState({ agentStatus: status });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    // let x =8;
    const { collektoUser } = this.props.authentication;
    const { agentReady, isOpen } = this.state;
    // this.alertsInterval = collektoUser?.role==="COLLECTOR"? setInterval(this.props.getAllAlerts(collektoUser?._id), 300000) : null;
    let { alertsCount, missedAlerts } = this.props.collectors;
    if (!alertsCount) {
      alertsCount = 0;
    }
    const sampleAlert = missedAlerts?.length ? missedAlerts[0] : {};
    const alertCols = [];
    for (let k in sampleAlert) {
      alertCols.push({
        dataField: k,
        text: k.toUpperCase(),
      });
    }
    const alertsRowEvent = {
      onClick: async (e, row, rowIndex) => {
        const { _id } = this.props?.authentication?.collektoUser;
        console.log("current row is", row);
        const axiosReq = Axios.create({
          headers: {
            collectorId: _id,
          },
        });
        console.log("expected row is: ", row);
        await axiosReq.put(
          `${REACT_APP_JAVA_API_URL}/api/v2/alerts/${row.id}/deactivate`
        );
        this.props.history.push(`/call-management`, {
          _id: row.loanId,
          Loan_ID: row.loanNumber,
        });
        // window.location.reload();
      },
    };
    const handleAlertCountClick = () => (dispatch) => {
      dispatch({ type: collectorConstants.SHOWALERTSMODAL, payload: true });
    };

    const handleCloseModal = () => {
      this.setState({ isModalVisible: false }); // Set isModalVisible to true when clicked
    };
    return (
      <header className="topbar header-style-1">
        <nav className="">
          <div className="nav-style-1">
            <Link className="navbar-brand" to="/">
              <img src="./assets/images/logo-symbol_1.png"></img>
              <img src="./assets/images/logo 1.png"></img>
            </Link>
            <div className="right-header d-flex align-items-center ">
              {role === "COLLECTOR" && location === "D" && (
                <>
                  <div className=" d-flex  align-items-center  justify-content-center gap-4  mr-4 ">
                    <CachedIcon
                      className="mr-3"
                      style={{ cursor: "pointer" }}
                      onClick={this.agentMonitorStatus}
                    />
                    <span className="m-0 user-detail">Status: </span>
                    <div className=" bg-white ml-1   px-4 text-warning  font-weight-bold ">
                      {Object.keys(this.state.agentStatus).length !== 0 &&
                        this.state.agentStatus?.status}
                    </div>
                  </div>
                  <div className="d-flex align-items-center mr-3">
                    {agentReady === "true" ? (
                      <div className="btn-dropdown">
                        <button
                          className="custom-button-status"
                          onClick={this.handleButtonClick}
                        >
                          Break <MdExpandMore color="red" size={20} />
                        </button>
                        {isOpen && (
                          <div className="dropdown-menu-status">
                            {statusOptions.map((option) => (
                              <div
                                // ref={this.selectRef}
                                key={option.value}
                                className="dropdown-option"
                                onClick={() => this.handleSelectChange(option)}
                              >
                                {option.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="btn-ready ">
                        <button
                          className="btn btn-primary custom-ready-btn"
                          onClick={(e) => this.handleReadyChange(e)}
                        >
                          Ready
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="d-flex h-10 align-items-center dropdown u-pro">
                <a
                  className="nav-link  waves-effect waves-dark"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="user-detail">
                    <span>
                      {collektoUser?.role === "COLLECTOR"
                        ? "Agent"
                        : collektoUser?.role === "MANAGER"
                        ? "Manager"
                        : collektoUser?.role === "ADMIN"
                        ? "Admin"
                        : collektoUser?.role === "CUSTOMER"
                        ? "Super Manager"
                        : "User"}
                    </span>
                    <span>
                      &nbsp;
                      {collektoUser?.role === "CUSTOMER"
                        ? collektoUser?.client
                        : collektoUser?.fullName}
                    </span>
                  </span>
                  <img
                    src={`./assets/images/Frame 47.png`}
                    className="profile-pic img-fluid"
                    alt="user"
                  />
                </a>

                <div className="dropdown-menu">
                  {collektoUser?.role === "ADMIN" && (
                    <Link to={`/myprofile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  {collektoUser?.role === "MANAGER" && (
                    <Link to={`/profile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  {collektoUser?.role === "COLLECTOR" && (
                    <Link to={`/my-profile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  {collektoUser?.role === "CUSTOMER" && (
                    <Link to={`/my-profile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  <a
                    dangerouslysethref="javascript:void(0);"
                    onClick={this.logout}
                    className="dropdown-item"
                  >
                    <img width="15px" src="assets/images/power-off.svg" />{" "}
                    Logout
                  </a>

                  {/* text*/}
                </div>
              </div>
            </div>
            {collektoUser?.role === "COLLECTOR" && (
              <span
                className="alertCount2"
                title="alerts count"
                style={{ zIndex: 1000, cursor: "pointer" }}
                onClick={(e) => this.handleCloseModal(true)}
              >
                {alertsCount}
              </span>
            )}
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  collectors: state.collectors,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getMissedAlerts: (collectorId) => dispatch(getMissedAlerts(collectorId)),
  getAllAlerts: (collectorId) => dispatch(getAllAlerts(collectorId)),
  handleCloseModal: (payload) => dispatch(handleModal(payload)),
  getCollectorProcess: (username) => dispatch(getCollectorProcess(username)),
  // authMe : () => dispatch(authMe())
});

export default connect(mapStateToProps, mapDispatchToProps)(AltHeader);
